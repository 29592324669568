/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  margin: 0;
  font-family: 'SLB Sans';
  font-size: 14px;
}

.app-content {
  padding: 20px;
}

highcharts-chart {
  display: inline-block;
  min-width: 0;
  width: 100%;
  height: 100%;
}